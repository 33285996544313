import React, {useState, useEffect} from "react";
import { DataTable } from "primereact/datatable";
import {Column} from "primereact/column";
import '../../assets/css/datatable.css';
import { Card } from "primereact/card";
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Checkbox } from "primereact/checkbox";
import { getClubReport } from "api/reports";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import '../../assets/css/primereactcustom.css';
import report from '../../assets/json/patextdata.json';
const ClubUserReport = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDay);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    const currentWeekArray = [startOfWeek, endOfWeek];
    const [first, setFirst] = useState(0);
    const [dates, setDates] = useState(currentWeekArray);
    const clubData = useSelector((state) => state.club);
    const user = useSelector((state) => state.user);
    const [isPastMidnightChecked, setIsPastMidnightChecked] = useState(false);
    const [isNonCompletedChecked, setIsNonCompletedChecked] = useState(false);
    const [reportData, setReportData] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
      // Execute your logic when the date range is selected
      if (dates.filter(date => date !== null).length === 2) {
        const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
        var startDate = dates[0].toLocaleDateString('en-US', options);
        var endDate = dates[dates.length - 1].toLocaleDateString('en-US', options);
        const formDataJson = {
          'start_date': startDate,
          'end_date': endDate,
          'orders_past_midnight': isPastMidnightChecked,
          'non_completed': isNonCompletedChecked
        };
        
        getClubReport(formDataJson, user?.authtoken, clubData?.club_id)
        .then((response) => {
          setReportData(response.data);
        }).catch((error) => {
          if(error.response.status === 400) {
            localStorage.clear();
            dispatch({
              type: 'LOGOUT',
              payload: {
              },
            });
            history.push('/login');
          }
        });
      }
      
    }, [dates, isNonCompletedChecked, isPastMidnightChecked]);  
    const TableData = (rowData) => {
        return(
            <>
              <p style={{margin: '0px'}}>{rowData.table_number}</p>
              <small id="user">
              {rowData.user_name}
              </small>
            </>
        )
    }
    const DateAndTime = (rowData) => {
      return(
        <>
          <span>{new Intl.DateTimeFormat('default', {day: '2-digit',month: 'short', hour: '2-digit', minute: '2-digit'}).format(new Date(rowData.request_time))}</span>
        </>
      )
    }
    const orderStatus = (rowData) => {
      const statusConfig = [
          { status: 1, icon: "pi pi-upload", severity: "warning", value: report.userReport.requested },
          { status: 2, icon: "pi pi-sync", severity: "info", value: report.userReport.inProgress },
          { status: 3, icon: "pi pi-check", severity: "success", value: report.userReport.completed },
          { status: 4, icon: "pi pi-times", severity: "danger", value: report.userReport.rejected },
          { status: 5, icon: "pi pi-play-circle", value: report.userReport.playing },
          { status: 6, icon: "pi pi-wallet", style: { backgroundColor: "coral" }, value: report.userReport.refunded }
      ];
  
      const status = statusConfig.find(config => config.status === rowData.status);
  
      if (status) {
          const { icon, severity, value, style } = status;
          return <Tag className="mr-2" icon={icon} severity={severity} value={value} style={style}></Tag>;
      }
      return null;
  };
  
  
    const handleRange = (value) => {
      setDates(value);
    }
    const totalPayment = (reportData || [])
    .filter(item => item.status === 3) // Filter entries with status 3 (completed)
    .reduce((acc, curr) => acc + curr.payment, 0); // Sum up payments

    const reportHeader = () => {
      return(
        <>
          <div className="row">
            <div className="col-md-6">
                <h3 style={{margin:"0px"}}>{report.userReport.clubReport}</h3>
            </div>
            <div className="col-md-6 text-right">
                <h4 style={{color:"#000000", display:"contents"}}>{report.userReport.total}  ${totalPayment}</h4>
            </div>
          </div>
        </>
      )
    }
    const handleMidnightOrdersCheck = () => {
      setIsPastMidnightChecked(!isPastMidnightChecked);
    }
    const handleNonCompletedOrdersCheck = () => {
      setIsNonCompletedChecked(!isNonCompletedChecked);
    }
    const renderSerialNumber = (rowData, column) => {
      return column.rowIndex + 1; // Serial numbers start from 1
    };
    const header = (
      <div className="container">
        <div className="row">
          <div className="col-md-3">
          </div>
          <div className="col-md-9">
            <div className="d-flex justify-content-end">
              <div className="p-inputgroup reportCalenderParent" style={{alignItems:"baseline"}}>
                <div className="form-group form-check" style={{marginRight:"15px"}}>
                  <Checkbox
                    inputId="past_midnight_orders"
                    checked={isPastMidnightChecked}
                    onChange={(e) => handleMidnightOrdersCheck(e.checked)}
                    className="form-check-input"
                  />
                  <label htmlFor="past_midnight_orders" className="form-check-label ml-2" style={{color:"black"}}>
                    {report.userReport.includeOrders}
                  </label>
                </div>
                <div className="form-group form-check" style={{marginRight:"15px"}}>
                  <Checkbox
                    inputId="non-completed-orders"
                    checked={isNonCompletedChecked}
                    onChange={(e) => handleNonCompletedOrdersCheck(e.checked)}
                    className="form-check-input"
                  />
                  <label htmlFor="non-completed-orders" className="form-check-label ml-2" style={{color:"black"}}>
                    {report.userReport.includeNonCompletedOrders}
                  </label>
                </div>
                <Calendar value={dates} onChange={(e) => handleRange(e.value)} selectionMode="range" readOnlyInput showIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    
    return(
        <>
          <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                      <Button label={report.userReport.back} icon="pi pi-angle-left" link style={{textDecoration:"none"}} onClick={() => history.go(-1)}/>
                    </div>                    
                </div>
                <Card title={reportHeader}>
                  <DataTable
                      value={reportData}
                      // rowClassName={rowClass}
                      paginator rows={25} rowsPerPageOptions={[25, 50]}
                      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      totalRecords={reportData?.length}
                      first={first}
                      // globalFilterFields={[clublist.clubsList.clubname, clublist.clubsList.clubcity]}
                      emptyMessage={report.userReport.noRecord}
                      className="p-datatable-striped"
                      header={header}
                    >
                      <Column field="id" header={report.userReport.id} body={renderSerialNumber}/>
                      <Column field="event" header={report.userReport.table} sortable body={(rowData) => TableData(rowData)}/>
                      {/* <Column field="id" header="Id" sortable/> */}
                      <Column field="timestamp" header={report.userReport.timeStamp} sortable body={(rowData) => DateAndTime(rowData)}/>
                      <Column field="status" header={report.userReport.status} body={(rowData) => orderStatus(rowData)}  sortable />
                      <Column field="payment" header={report.userReport.amount} sortable />
                  </DataTable>
                </Card>
              </div>
            </div>
          </div>
        </>
    )
}

export default ClubUserReport;