// Import your components
import Dashboard from "views/Dashboard";
import ClubSetup from "views/clubcomponents/ClubSetup";
import ClubsList from "views/clubcomponents/ClubsList";
import SetUpMasterAccount from "views/SetupMasterAccount";
import ClubUserList from "views/clubusercomponents/ClubUserList";
import Login from "views/auth/Login";
import ClubTables from "views/clubcomponents/ClubTables";
import DigitalSignCategories from "views/digitalsigncategoryandclub/DigitalSignsCategories";
import DigitalSignsList from "views/digitalsigncategoryandclub/DigitalSigns";
import ClubUserReport from "views/clubreports/ClubUserReport";
import UpdateApp from "views/updateappversion/UpdateApp";
import ClubScreensList from "views/clubscreens/ClubScreensList";
import Promocode from "views/promocodes/promocode";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: ""
  },
  {
    path: "/setup-club",
    name: "Setup a Club",
    icon: "nc-icon nc-simple-add",
    component: ClubSetup,
    layout: ""
  },
  {
    path: "/edit-club",
    name: "Edit Club",
    icon: "",
    component: ClubSetup,
    layout: "",
    redirect: '/edit-club'
  },
  {
    path: "/club-tables",
    name: "Club Tables",
    icon: "",
    component: ClubTables,
    layout: "",
    redirect: '/club-tables'
  },
  {
    path: "/digital-sign-categories",
    name: "Digital Sign Categories",
    icon: "",
    component: DigitalSignCategories,
    layout: "",
    redirect: '/digital-sign-categories'
  },
  {
    path: "/digital-signs-list",
    name: "Digital Signs List",
    icon: "",
    component: DigitalSignsList,
    layout: "",
    redirect: '/digital-signs-list'
  },
  {
    path: "/club-report",
    name: "Club Report",
    icon: "",
    component: ClubUserReport,
    layout: "",
    redirect: '/club-report'
  },
  {
    path: "/view-clubs",
    name: "View Clubs",
    icon: "nc-icon nc-bank",
    component: ClubsList,
    layout: ""
  },
  {
    path: "/master-templates",
    name: "Master Templates",
    icon: "nc-icon nc-single-copy-04",
    component: DigitalSignCategories,
    layout: "",
    // redirect: '/manage-admin-users'
  },
  {
    path: "/master-digital-sign",
    name: "Master Digital Signs",
    icon: "nc-icon nc-single-copy-04",
    component: DigitalSignsList,
    layout: "",
    redirect: '/master-digital-sign'
  },
  {
    path: "/app-update",
    name: "App Update",
    icon: "nc-icon nc-android",
    component: UpdateApp,
    layout: ""
  },
  // {
  //   path: "/setup-master-account",
  //   name: "Setup Master Account",
  //   icon: "nc-icon nc-circle-09",
  //   component: SetUpMasterAccount,
  //   layout: ""
  // },
  {
    path: "/manage-admin-users",
    name: "Manage Admin Users (PA)",
    icon: "nc-icon nc-settings-90",
    component: ClubUserList,
    layout: "",
    redirect: '/manage-admin-users'
  },
  {
    path: "/club-screens",
    name: "Club Screens",
    icon: "",
    component: ClubScreensList,
    layout: "",
    redirect: '/club-screens'
  },
  {
    path: "/promocodes",
    name: "Promocodes",
    icon: "",
    component: Promocode,
    layout: "",
    redirect: '/promocodes'
  },
  // {
  //   path: "/login",
  //   name: "Manage Super Admin Profile",
  //   icon: "nc-icon nc-settings-90",
  //   component: Login,
  //   layout: ""
  // }
];

export default dashboardRoutes;
