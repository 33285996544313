import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { getClubScreens, updateClubScreen, addClubScreen, deleteClubScreen } from "api/clubscreen";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import screenTextData from '../../assets/json/patextdata.json';
const ClubScreensList = () => {
    const [loading, setLoading] = useState(false);
    const [screens, setScreens] = useState([]);
    const [visible, setVisible] = useState(false);
    const [screenTitle, setScreenTitle] = useState("");
    const [screenOrder, setScreenOrder] = useState("");
    const [errors, setErrors] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const club_id = searchParams.get('club_id');
    const noscreens = searchParams.get('noscreens');
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchClubScreens();
    }, [loading]); // Trigger API call when loading state changes

    const fetchClubScreens = () => {
        getClubScreens(club_id, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    setScreens(response.data.club_screens);
                }
            }).catch((error) => {
                if (error.response.status === 404 && noscreens) {
                    setLoading(false);
                    setScreens([]);
                    setVisible(true);
                    setScreenOrder("");
                    setScreenTitle("");
                }
                if(error.response.status === 400) {
                    localStorage.clear();
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                      },
                    });
                    history.push('/login');
                }
            });
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
        let _screens = [...screens];
        let { newData, index } = e;
        _screens[index] = newData;
        setScreens(_screens);

        const updatedScreenData = {
            "screen_id": newData.screen_id,
            "screen_title": newData.screen_title,
            "club_id": newData.club_id,
            "z_order": parseInt(newData.z_order)
        };

        updateClubScreen(updatedScreenData, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(screenTextData.clubScreens.updateScreenMsg);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                        error: error
                    },
                });
                history.push("/something-went-wrong");
            });
    };

    const handleHideDialog = () => {
        setVisible(false);
        setErrors({});
    };

    const openAddScreenDialog = () => {
        setVisible(true);
        setLoading(false);
    };

    const validateFields = () => {
        const titleError = screenTitle.trim() ? '' : screenTextData.clubScreens.screenTitleValidateMsg;
        const orderError = screenOrder.trim() ? '' : screenTextData.clubScreens.screenOrderValidateMsg;
        setErrors({
            screenTitle: titleError,
            screenOrder: orderError,
        });
        return !(titleError || orderError);
    };

    const handleSave = () => {
        if (validateFields()) {
            setLoading(true);
            let screenData = {
                "screen_title": screenTitle,
                "club_id": club_id,
                "z_order": parseInt(screenOrder)
            }
            addClubScreen(screenData, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(screenTextData.clubScreens.addScreenMsg);
                    fetchClubScreens(); // Fetch latest data after adding screen
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                        error: error
                    },
                });
                history.push("/something-went-wrong");
            })
            // Reset state and close dialog
            setScreenTitle('');
            setScreenOrder('');
            setVisible(false);
            setErrors({});
        }
    };

    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button
                label={screenTextData.clubScreens.cancel}
                icon="pi pi-times"
                onClick={() => handleHideDialog()}
                className="p-button-text cancel-button"
            />
            <Button
                label={screenTextData.clubScreens.save}
                icon="pi pi-check"
                onClick={handleSave}
                loading={loading}
                autoFocus
                className="normal-button"
            />
        </div>
    );

    const confirmDelete = (rowData) => {
        confirmDialog({
            message: screenTextData.clubScreens.deleteScreenCnfmMsg,
            header: screenTextData.clubScreens.deleteScreenHeader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            accept: () => handleDeleteScreen(rowData), 
            reject: () => {} // Empty function to handle rejection
        });
    };

    const handleDeleteScreen = (rowData) => {
        deleteClubScreen(rowData.screen_id, user?.authtoken)
        .then((response) => {
            if(response.status === 200) {
                toast.success(response.data.msg);
                setLoading(true);
                fetchClubScreens(); // Fetch latest data after deleting screen
            }
        }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                    error: error
                },
            });
            history.push("/something-went-wrong");
        })
    }

    return (
        <>
            <ConfirmDialog />
            <Dialog
                header={screenTextData.clubScreens.addScreenHeader}
                visible={visible}
                style={{ width: '25rem' }}
                draggable={false}
                onHide={() => handleHideDialog()}
                footer={footerContent}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{screenTextData.clubScreens.screenTitle}<span className="redStar">*</span></label>
                            <InputText
                                className={`form-control ${errors.screenTitle ? "is-invalid" : ""} gray-placeholder`}
                                value={screenTitle}
                                name="screenTitle"
                                style={{ display: 'flex' }}
                                placeholder={screenTextData.clubScreens.screenTitlePlaceholder}
                                onChange={(e) => {
                                    setScreenTitle(e.target.value);
                                    setErrors((prevErrors) => ({ ...prevErrors, screenTitle: e.target.value.trim() ? '' : screenTextData.clubScreens.screenTitleValidateMsg }));
                                }}
                            />
                            {errors.screenTitle && (
                                <div className="invalid-feedback">{errors.screenTitle}</div>
                            )}
                        </div>
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{screenTextData.clubScreens.screenOrder}<span className="redStar">*</span></label>
                            <InputText
                                className={`form-control ${errors.screenOrder ? "is-invalid" : ""} gray-placeholder`}
                                value={screenOrder}
                                name="screenOrder"
                                maxLength={2}
                                keyfilter="int"
                                style={{ display: 'flex' }}
                                placeholder={screenTextData.clubScreens.screenOrderPlaceholder}
                                onChange={(e) => {
                                    setScreenOrder(e.target.value);
                                    setErrors((prevErrors) => ({ ...prevErrors, screenOrder: e.target.value.trim() ? '' :  screenTextData.clubScreens.screenOrderValidateMsg}));
                                }}
                            />
                            {errors.screenOrder && (
                                <div className="invalid-feedback">{errors.screenOrder}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>

            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                        <Button label={screenTextData.clubScreens.back} icon="pi pi-angle-left" link style={{ textDecoration: "none" }} onClick={() => history.go(-1)} />
                    </div>
                    <div className="col-6 text-right buttonParent">
                        <Button label={screenTextData.clubScreens.addScreen} icon="pi pi-plus" onClick={openAddScreenDialog} className="normal-button btn-sm" />
                    </div>
                </div>

                <div className="card p-fluid">
                    <DataTable
                        value={screens && screens}
                        editMode="row"
                        dataKey="id"
                        onRowEditComplete={onRowEditComplete}
                        tableStyle={{ minWidth: '50rem' }}
                        rowEditorSaveIcon={<Button type="button" className="p-button-sm p-button-text" icon="pi pi-check"></Button>}
                        rowEditorInitIcon={<Button type="button" className="p-button-sm p-button-text" icon="pi pi-pencil"></Button>}
                        rowEditorCancelIcon={<Button type="button" className="p-button-sm p-button-text" icon="pi pi-times"></Button>}
                    >
                        <Column
                            field="screen_title"
                            header={screenTextData.clubScreens.screenName}
                            editor={(options) => textEditor(options)}
                            style={{ width: '20%' }}
                            sortable
                        ></Column>
                        <Column
                            field="z_order"
                            header={screenTextData.clubScreens.screenOrder}
                            editor={(options) => textEditor(options)}
                            style={{ width: '20%' }}
                            sortable
                        ></Column>
                        <Column
                            header=""
                            rowEditor={true}
                            headerStyle={{ width: '0%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'left' }}
                        ></Column>
                        <Column
                            header=""
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'left' }}
                            body={(rowData)=> <Button type="button" className="p-button-sm p-button-text" icon="pi pi-trash" onClick={()=>confirmDelete(rowData)}></Button>}
                        ></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ClubScreensList;
