import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { getPromoCodes, addPromoCode, deletePromoCode, updatePromoCode } from "api/promocodes";
import { toast } from "react-toastify";
import '../../assets/css/primereactcustom.css';
import promo from '../../assets/json/patextdata.json';
const Promocode = () => {
    const [promocodes, setPromoCodes] = useState([]);
    const [checkLimited, setCheckLimited] = useState(false);
    const [promoCodeName, setPromoCodeName] = useState('');
    const [discount, setDiscount] = useState('');
    const [usageLimit, setUsageLimit] = useState('');
    const [errors, setErrors] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const club_id = searchParams.get('id');
    const user = useSelector((state) => state.user);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPromoCodes();
    }, [loading]);

    const fetchPromoCodes = () => {
        getPromoCodes(user?.authtoken, club_id)
            .then((response) => {
                if (response.status === 200) {
                    setPromoCodes(response.data.promocodes);
                }
            })
            .catch((error) => {
                toast.error(promo.promocode.fetchFailed);
            });
    };

    const onRowEditComplete = (e) => {
        let _promocodes = [...promocodes];
        let { newData, index } = e;

        _promocodes[index] = newData;

        setPromoCodes(_promocodes);
        const updatedPromoCodeData = {
            "promocode_name": newData.name.toUpperCase(),
            "code": newData.code.toUpperCase(),
            "remaining_attempts": parseInt(newData.remaining_attempts),
            "discount": parseInt(newData.discount),
            "description": "",
            "status": newData.status,
            "discount_type": "percent",
            "club_id": newData.club_id,
            "id": newData.id
        };
        updatePromoCode(updatedPromoCodeData, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(`${response.data.promocode_details.name}`+` `+`${promo.promocode.promoUpdateMsg}`);
                }
            })
            .catch((error) => {
                toast.error(promo.promocode.failedUpdate);
            });
    };

    const handleStatusUpdate = (value, rowData) => {
        const updatedPromoCodeData = {
            "promocode_name": rowData.name.toUpperCase(),
            "code": rowData.code.toUpperCase(),
            "remaining_attempts": parseInt(rowData.remaining_attempts),
            "discount": parseInt(rowData.discount),
            "description": "",
            "status": value ? 1 : 0,
            "discount_type": "percent",
            "club_id": rowData.club_id,
            "id": rowData.id
        };
        updatePromoCode(updatedPromoCodeData, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(!loading);
                    toast.success(`${response.data.promocode_details.name} Promocode ${response.data.promocode_details.status === 1 ? "enabled!" : 'disabled!'}`);
                }
            })
            .catch((error) => {
                toast.error(promo.promocode.failedStatus);
            });
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const usageLimitEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.status === 1} onChange={(e) => handleStatusUpdate(e.value, rowData)} />
        );
    };

    const usageLimitTemplate = (rowData) => {
        return new Intl.NumberFormat().format(rowData.remaining_attempts);
    };

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const confirmDelete = (rowData) => {
        confirmDialog({
            message: promo.promocode.deleteCnfmMsg,
            header: promo.promocode.deleteHeader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            accept: () => handleDeletePromoCode(rowData),
            reject: () => {} // Empty function to handle rejection
        });
    };

    const handleDeletePromoCode = (rowData) => {
        deletePromoCode(rowData.id, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    setPromoCodes(promocodes.filter((promo) => promo.id !== rowData.id));
                    toast.success(promo.promocode.promoDeleteMsg);
                }
            })
            .catch((error) => {
                toast.error(promo.promocode.failedDelete);
            });
    };

    const handleSave = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const promoCodeData = {
            "promocode_name": promoCodeName.toUpperCase(),
            "code": promoCodeName.toUpperCase(),
            "remaining_attempts": checkLimited ? null : parseInt(usageLimit),
            "discount": parseInt(discount),
            "description": "",
            "status": 1,
            "discount_type": "percent",
            "club_id": club_id
        };

        addPromoCode(promoCodeData, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(!loading);
                    makeEmptyForm();
                    setVisible(false);
                    toast.success(`New Promocode ${response.data.promocode_details.name} created successfully!`);
                }
            })
            .catch((error) => {
                toast.error(promo.promocode.failedCreate);
            });
    };

    const validateForm = () => {
        const errors = {};
        if (!promoCodeName) errors.promoCodeName = promo.promocode.promoNameErrorMsg;
        if (!discount) errors.discount = promo.promocode.discountErrorMsg;
        else if (discount < 0 || discount > 100) errors.discount = promo.promocode.discountErrorMsgTwo;
        if (!checkLimited && !usageLimit) errors.usageLimit = promo.promocode.usageLimitError;

        return errors;
    };

    const makeEmptyForm = () => {
        setPromoCodeName('');
        setDiscount('');
        setUsageLimit('');
        setErrors({});
    };

    const handlePromoCodeChange = (value) => {
        setPromoCodeName(value);
        if (errors.promoCodeName) {
            setErrors({ ...errors, promoCodeName: undefined });
        }
    };

    const handleDiscountChange = (value) => {
        setDiscount(value);
        if (errors.discount) {
            setErrors({ ...errors, discount: undefined });
        }
    };

    const handleUsageLimitChange = (value) => {
        setUsageLimit(value);
        if (errors.usageLimit) {
            setErrors({ ...errors, usageLimit: undefined });
        }
    };

    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button
                label={promo.promocode.cancel}
                icon="pi pi-times"
                onClick={() => handleHideDialog()}
                className="p-button-text cancel-button"
            />
            <Button
                label={promo.promocode.save}
                icon="pi pi-check"
                onClick={handleSave}
                loading={loading}
                autoFocus
                className="normal-button"
            />
        </div>
    );

    const handleHideDialog = () => {
        setVisible(false);
        setErrors({});
    };

    const openDialog = () => {
        setVisible(true);
        setLoading(false);
    };

    return (
        <>
            <ConfirmDialog />
            <Dialog
                header={promo.promocode.addPromoHeader}
                visible={visible}
                style={{ width: '25rem' }}
                draggable={false}
                onHide={() => handleHideDialog()}
                footer={footerContent}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{promo.promocode.promoName}<span className="redStar">*</span></label>
                            <InputText
                                name="promocodeName"
                                style={{ display: 'flex', width: '100%' }}
                                placeholder={promo.promocode.promoNamePlaceholder}
                                value={promoCodeName.toUpperCase()}
                                onChange={(e) => handlePromoCodeChange(e.target.value)}
                                onInput={() => setErrors({ ...errors, promoCodeName: undefined })}
                            />
                            {errors.promoCodeName && <small className="p-error">{errors.promoCodeName}</small>}
                        </div>
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{promo.promocode.discount}<span className="redStar">*</span></label>
                            <InputText
                                name="discount"
                                maxLength={3}
                                keyfilter="int"
                                style={{ display: 'flex', width: '100%' }}
                                placeholder={promo.promocode.discountPlaceholder}
                                value={discount}
                                onChange={(e) => handleDiscountChange(e.target.value)}
                                onInput={() => setErrors({ ...errors, discount: undefined })}
                            />
                            {errors.discount && <small className="p-error">{errors.discount}</small>}
                        </div>
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{promo.promocode.unlimited}</label><br />
                            <InputSwitch checked={checkLimited} onChange={(e) => setCheckLimited(e.value)} />
                        </div>

                        {!checkLimited && <div className="form-group" style={{ width: "100%" }}>
                            <label>{promo.promocode.usageLimit}<span className="redStar">*</span></label>
                            <InputText
                                name="usageLimit"
                                maxLength={5}
                                keyfilter="int"
                                style={{ display: 'flex', width: '100%' }}
                                placeholder={promo.promocode.usageLimitPlaceholder}
                                value={usageLimit}
                                onChange={(e) => handleUsageLimitChange(e.target.value)}
                                onInput={() => setErrors({ ...errors, usageLimit: undefined })}
                            />
                            {errors.usageLimit && <small className="p-error">{errors.usageLimit}</small>}
                        </div>
                        }
                    </div>
                </div>
            </Dialog>
            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                        <Button label={promo.promocode.back} icon="pi pi-angle-left" link style={{ textDecoration: "none" }} onClick={() => history.go(-1)} />
                    </div>
                    <div className="col-6 text-right buttonParent">
                        <Button label={promo.promocode.addPromo} icon="pi pi-plus" onClick={openDialog} className="normal-button btn-sm" />
                    </div>
                </div>
                <div className="card p-fluid">
                    <DataTable emptyMessage={promo.promocode.noRecord} value={promocodes} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
                        <Column sortable field="name" header={promo.promocode.promoName} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column sortable field="discount" header={promo.promocode.discount} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column sortable field="remaining_attempts" header={promo.promocode.usageLimit} body={usageLimitTemplate} editor={(options) => usageLimitEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="Status" header={promo.promocode.status} body={statusBodyTemplate} style={{ width: '20%' }}></Column>
                        <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column header="" headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'left' }} body={(rowData) => <Button type="button" className="p-button-sm p-button-text" icon="pi pi-trash" onClick={() => confirmDelete(rowData)}></Button>}></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default Promocode;
