import React, {useState, useEffect} from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { getDigitalSignCategoriesOfClub, addDigitalSignCategory, deleteDigitalSignCategory, updateDigitalSignCategory } from "api/digitalsignsandcategories";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../../assets/css/custom.css'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { uploadMedia } from "api/uploadmedia";
import categoryText from '../../assets/json/patextdata.json';
import NotAvailableIcon from "components/NotAvailableIcons/NotAvailableIcon";
import { Image } from "primereact/image";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from 'primereact/tooltip';
import { useDispatch } from 'react-redux';
import { getMasterDigitalSignCategories } from "api/masterdigitalsignandcategories";
import {addMasterDigitalSignCategory} from "api/masterdigitalsignandcategories";
import {deleteMasterDigitalSignCategory} from "api/masterdigitalsignandcategories";
import {updateMasterDigitalSignCategory} from "api/masterdigitalsignandcategories";
const DigitalSignCategories = (props) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [visible, setVisible] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [categoryOrder, setCategoryOrder] = useState("");
    const [categoryList, setCategoryList] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [editCategory, setEditCategory] = useState(null);
    const [fileURI, setFileURI] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0); // State variable for upload progress
    const user = useSelector((state) => state.user);
    const club = useSelector((state) => state.club);
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if(!isEdit && props.perspective === "club"){
            getDigitalSignCategoriesOfClub(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    setCategoryList(response.data.club_digital_sign_types);
                }
            })
            .catch((error) => {
                if(error.response.status === 400) {
                    localStorage.clear();
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                      },
                    });
                    history.push('/login');
                }
            });
        } else if(props.perspective === "master"){
            getMasterDigitalSignCategories(user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    setCategoryList(response.data.digital_sign_types);
                }
            })
            .catch((error) => {
                if(error.response.status === 400) {
                    localStorage.clear();
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                      },
                    });
                    history.push('/login');
                }
            });
        }
    },[loading]);
    const handleClickForCategory = (category_id) => {
        if(props.perspective === "master"){
            history.push(`/master-digital-sign?id=${category_id}`);
        } else{
            history.push(`/digital-signs-list?id=${category_id}`);
        }
    };
    const header = (imageURI) => (
        <div className="container" style={{backgroundImage: `url(${imageURI})`,height:'270px', width:'250px', backgroundSize:'cover',backgroundRepeat:'no-repeat', backgroundPosition:'center', marginTop:'12.8px'}}></div>
    );

    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button label={categoryText.mediacategory.cancel} icon="pi pi-times" onClick={() => handleHideDialog()} className="p-button-text cancel-button" />
            <Button disabled={(fileURI && categoryName ? false : true)} label={isEdit ? categoryText.mediacategory.update : categoryText.mediacategory.save} icon="pi pi-check" onClick={() => !isEdit ? addCategory() : updateCategory(editCategory)} autoFocus className="normal-button" />
        </div>
    );
    const openCategoryDialog = () => {
        setIsEdit(false);
        setVisible(true);
    }
    const handleHideDialog = () => {
        setCategoryName("");
        setCategoryOrder("");
        setFileURI(null);
        setErrors({});
        setVisible(false);
    }
    const addCategory = () => {
        if(validateForm()) {
            const categoryData = {
                "club_id":id,
                "title": categoryName,
                "z_index": parseInt(categoryOrder),
                "image_url": fileURI
            }
            const formData = new FormData();
            // Append each key-value pair from clubtabledata to the FormData
            Object.entries(categoryData).forEach(([key, value]) => {
                formData.append(key, value);
            });
            if(props.perspective === "master") {
                addMasterDigitalSignCategory(categoryData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        setCategoryName("");
                        setCategoryOrder("");
                        setLoading(!loading);
                        setFileURI(null);
                        toast.success(categoryText.mediacategory.addcatmsg);
                        setVisible(false);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                        error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            } else{
                addDigitalSignCategory(categoryData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        setCategoryName("");
                        setCategoryOrder("");
                        setLoading(!loading);
                        setFileURI(null);
                        toast.success(categoryText.mediacategory.addcatmsg);
                        setVisible(false);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                        error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            }
            
        }
    }
    const onUpload = (icon, event) => {
        const formData = new FormData();
        formData.append('file', event.files[0]);
        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        // Add event listeners for progress tracking
        xhr.upload.addEventListener('progress', (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadProgress(progress);
        });
        
        uploadMedia(formData, club.club_id, user?.authtoken, (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(progress);
        })
        .then((response) => {
            if(response.status === 200) {
                validateField(icon, response.data.url);
                setFileURI(response.data.url);
                setUploadProgress(0);
            }
        })
        .catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        })
    };
    const handleCategoryName = (fieldName,value) => {
        setCategoryName(value);
        validateField(fieldName,value);
    }
    const handleCategoryOrder = (fieldName,value) => {
        setCategoryOrder(value);
        validateField(fieldName,value);
    }
    const handleAccept = (id) => {
        if(props.perspective === "master") {
            deleteMasterDigitalSignCategory(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(categoryText.mediacategory.deleteMsg);
                    setLoading(!loading);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        } else {
            deleteDigitalSignCategory(id, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(response.data.msg);
                    setLoading(!loading);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            })
        }
    }
    const handleReject = () => {
    
    }
    const confirm = (event,category) => {
        event.stopPropagation();
        confirmDialog({
            message: categoryText.mediacategory.deletecatmsg,
            header: categoryText.mediacategory.deletecatheader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            closeOnEscape: true,
            accept: () => handleAccept(category.id), 
            reject: () => handleReject(category.id)
        });
    };
    const footer = (category) => {
        return(
            <>
                <Button label={categoryText.mediacategory.edit} icon="pi pi-pencil" className="normal-button" onClick={(event) => handleEditCategory(event,category)}/>
                <Button label={categoryText.mediacategory.delete} severity="danger" icon="pi pi-trash" style={{ marginLeft: '0.5em' }} onClick={(event)=>confirm(event,category)} />
            </>
        )
    };

    const updateCategory = (category) => {
        setLoading(true);
        const formData = {
            "id": category.id,
            "club_id": category.club_id,
            "title": categoryName,
            "z_index": parseInt(categoryOrder),
            "image_url": fileURI
        }
        if(props.perspective === "master") {
            updateMasterDigitalSignCategory(formData, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(categoryText.mediacategory.updatecatmsg);
                    setCategoryName("");
                    setCategoryOrder("");
                    setFileURI(null);
                    setVisible(false);
                    setLoading(false);
                    setIsEdit(false);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            });
        } else {
            updateDigitalSignCategory(formData, user?.authtoken)
            .then((response) => {
                if(response.status === 200) {
                    toast.success(categoryText.mediacategory.updatecatmsg);
                    setCategoryName("");
                    setCategoryOrder("");
                    setFileURI(null);
                    setVisible(false);
                    setLoading(false);
                    setIsEdit(false);
                }
            }).catch((error) => {
                dispatch({
                    type: 'IS_ERROR',
                    payload: {
                    error: error
                    },
                });
                history.push("/something-went-wrong");
            });
        }
    }

    const handleEditCategory = (event,category) => {
        event.stopPropagation();
        setIsEdit(true);
        setVisible(true);
        setLoading(false);
        setCategoryName(category.title);
        setCategoryOrder(category.z_index);
        setFileURI(category.image_url);
        setEditCategory(category);
    }
    const validateField = (fieldName,value) => {
        let error = "";
        if(!value && fieldName === 'categoryName') {
            error = categoryText.mediacategory.catnameerr;
        }
        if(!value && fieldName === 'categoryOrder') {
            error = categoryText.mediacategory.catordererr;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: error
        }));
      
        return error;
    };
    const validateForm = () => {
        const newErrors = {};
        if (!categoryName) {
          newErrors.categoryName = categoryText.mediacategory.catnameerr;
        }
        if(!fileURI) {
            newErrors.icon = categoryText.mediacategory.iconerror;
        }
        if(!categoryOrder) {
            newErrors.categoryOrder = categoryText.mediacategory.catordererr;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };
    const cardTitle = (category) => {
        return(
            <>
                <Tooltip mouseTrack={true} target=".d-inline-block" />
                <span className="d-inline-block text-truncate" style={{maxWidth: '200px'}} data-pr-position="top" data-pr-tooltip={category.title}>{category.title}</span>
            </>
        )
    }

    return(
        <>
            <Dialog header={isEdit ? categoryText.mediacategory.editcategory : categoryText.mediacategory.addcategory} visible={visible} style={{ width: '30rem' }} draggable={false} onHide={() => handleHideDialog()} footer={footerContent}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{categoryText.mediacategory.name}<span className="redStar">*</span></label>
                            <InputText 
                                className={`form-control ${errors.categoryName ? "is-invalid" : ""} gray-placeholder`}
                                value={categoryName}
                                name="category_name"
                                onChange={(event) => handleCategoryName("categoryName", event.target.value)}
                                style={{ display: 'flex'}} 
                                placeholder={categoryText.mediacategory.catnameplaceholder} 
                            />
                            {errors.categoryName && (
                                <div className="invalid-feedback">{errors.categoryName}</div>
                            )}
                        </div>
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{categoryText.mediacategory.order}<span className="redStar">*</span></label>
                            <InputText 
                                className={`form-control ${errors.categoryOrder ? "is-invalid" : ""} gray-placeholder`}
                                value={categoryOrder}
                                name="category_order"
                                maxLength={3}
                                keyfilter="int"
                                onChange={(event) => handleCategoryOrder("categoryOrder", event.target.value)}
                                style={{ display: 'flex'}} 
                                placeholder={categoryText.mediacategory.catorderplaceholder} 
                            />
                            {errors.categoryOrder && (
                                <div className="invalid-feedback">{errors.categoryOrder}</div>
                            )}
                        </div>
                        <div className="form-group" style={{width:"100%"}}>
                            <label>{categoryText.mediacategory.icon}<span className="redStar">*</span></label>
                            <div className="card flex justify-content-center" style={{ border: (errors.icon !== undefined && fileURI === null) ? "1px solid #DD3B4A" : "", marginBottom:"15px" }}>
                                <FileUpload mode="basic" name="file[]" accept="image/*"  maxFileSize={5000000} onSelect={(event) => onUpload('icon', event)} chooseLabel={categoryText.mediacategory.browse} auto={false} />
                            </div>
                            {
                                uploadProgress > 0 && <div className="flex justify-content-center">
                                    <ProgressBar style={{height:"10px"}} value={uploadProgress} displayValueTemplate={(value) => `${value}%`} />
                                    <div className="flex justify-content-center" style={{fontSize:'12px', display:"flex"}}>
                                    {
                                        uploadProgress + '%'
                                    }
                                    </div>
                                </div>
                            }
                            <br/>
                            {
                                fileURI && <div className="card flex justify-content-center">
                                    <Image src={fileURI} zoomSrc={fileURI} alt="Image" width="80" height="60" preview />
                                </div>
                            }    
                            {errors.icon && (
                                <div style={{color:"#DD3B4A", fontSize:"80%", marginTop:"-25px"}}>{errors.icon}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog />
            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                        <Button label={categoryText.mediacategory.back} icon="pi pi-angle-left" link style={{textDecoration:"none"}} onClick={() => history.go(-1)}/>
                    </div>
                    <div className="col-6 text-right buttonParent">
                        <Button label={categoryText.mediacategory.addcategory} icon="pi pi-plus" onClick={openCategoryDialog} className="normal-button btn-sm" />
                    </div>
                </div>
                <div className={`row ${categoryList === null ? `justify-content-center` : ``}`}>
                    {
                        categoryList !== null ? categoryList?.map((category,index) => {
                            return(
                                <div className="col-md-3 mb-4" key={index}>
                                    <div className="card flex justify-content-center" >
                                        <Card className="cardcursor" footer={()=>footer(category)} onClick={() => handleClickForCategory(category?.id)} title={()=>cardTitle(category)} header={()=>header(category.image_url)}  style={{ textAlign: 'center' }}>
                                            
                                        </Card>
                                    </div>
                                </div>
                            )
                        })
                        : 
                        <NotAvailableIcon/>
                    }
                </div>
            </div>
        </>
    )
}
export default DigitalSignCategories;
