import React, { useState, useEffect } from "react";
import { setUpClub, getClub, getCityAndCountry } from "api/club";
import { toast } from "react-toastify";
import avatar from '../../assets/img/upload.png';
import { useSelector } from "react-redux";
import { FileUpload } from "primereact/fileupload";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { AutoComplete } from "primereact/autocomplete";
import clubdata from '../../assets/json/patextdata.json';
import '../../assets/css/primereactcustom.css'
import { useDispatch } from 'react-redux';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';

function ClubSetup(props) {
  const [file, setFile] = useState(avatar);
  const [clubName, setClubName] = useState("");
  const [clubAddress, setClubAddress] = useState("");
  const [clubCity, setClubCity] = useState("");
  const [noOfTable, setNoOfTable] = useState("");
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');
  const [errors, setErrors] = useState({});
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('payPerSign');

  const validateField = (fieldName, value) => {
    let error = "";
    if(fieldName === 'noOfTable' && !value) {
      error = clubdata.setupclub.errorfornooftable;
    }
    if (fieldName !== 'noOfTable' && !value) {
      error = `${fieldName} is required`;
    }
    if (fieldName === 'logo' && !value) {
      error = clubdata.setupclub.logoerror;
    }
  
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: error
    }));
  
    return error;
  };
  const validateForm = () => {
    const newErrors = {};

    if (!clubName) {
      newErrors.clubname = clubdata.setupclub.clubnameerror;
    }
    if(!noOfTable) {
      newErrors.noOfTable = clubdata.setupclub.tableerror;
    }
    if(!clubAddress) {
      newErrors.address = clubdata.setupclub.addresserror;
    }
    if(!clubCity) {
      newErrors.city = clubdata.setupclub.cityerror;
    }
    if(!file?.previewURL) {
      newErrors.logo = clubdata.setupclub.logoerror;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  useEffect(() => {
    dispatch({
      type: clubdata.clubsList.currentclub,
      payload: {},
    });
    if (props?.type === clubdata.setupclub.editclub) {
      getClub(id, user?.authtoken)
        .then((response) => {
          setClubName(response.data.club_details.ClubName);
          setClubAddress(response.data.club_details.ClubAddress);
          setClubCity(response.data.club_details.ClubCity);
          setNoOfTable(response.data.club_details.ClubTablesCount);
          setSelectedPaymentOption(response.data.club_details.ClubConfig.PaymentPreference);
          if (response.data.club_details.LogoUrl === "") {
            setFile(avatar);
          } else {
            setFile({
              image: response.data.club_details.LogoUrl,
              previewURL: response.data.club_details.LogoUrl
            });
          }
        }).catch((error) => {
          dispatch({
            type: 'IS_ERROR',
            payload: {
              error: error
            },
          });
          history.push("/something-went-wrong");
        });
    }
  }, []);

  const handleFileChange = (event) => {
    if (props?.type === clubdata.setupclub.editclub) {
      setIsImageUpdated(true);
    }
    const selectedFile = event.files[0];
    // Display a preview of the selected file
    if (selectedFile) {
      const fileError = validateField('logo', selectedFile);
      if(!fileError) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile({
            image: selectedFile,
            previewURL: reader.result,
          });
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setFile(avatar);
      }
    } else {
      setFile(avatar);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append('club_city', clubCity);
      formData.append('club_name', clubName);
      if (isImageUpdated) {
        formData.append('logo_url', file?.image);
      }
      formData.append('club_address', clubAddress);
      formData.append('club_id', id);
      formData.append('no_of_table', noOfTable);
      formData.append('payment_option', selectedPaymentOption);
      await setUpClub(formData, user?.authtoken)
        .then((response) => {
          if (response.status === 200) {
            setClubName("");
            setClubAddress("");
            setClubCity("");
            setNoOfTable("");
            setFile(avatar);
            setSelectedPaymentOption('payPerSign');
            toast.success(clubdata.setupclub.clubupdatesuccessmessage);
            setTimeout(() => {
              history.push("/view-clubs");
            }, 2000);
          }
        }).catch((error) => {
          dispatch({
            type: 'IS_ERROR',
            payload: {
              error: error
            },
          });
          history.push("/something-went-wrong");
        });
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append('club_city', clubCity);
      formData.append('club_name', clubName);
      formData.append('logo_url', file.image);
      formData.append('club_address', clubAddress);
      formData.append('no_of_table', noOfTable);
      formData.append('payment_option', selectedPaymentOption);
      await setUpClub(formData, user?.authtoken)
        .then((response) => {
          if (response.status === 200) {
            setClubName("");
            setClubAddress("");
            setClubCity("");
            setNoOfTable("");
            setFile(avatar);
            setSelectedPaymentOption('payPerSign');
            toast.success(clubdata.setupclub.clubaddsuccessmessage);
            setTimeout(() => {
              history.push("/view-clubs");
            }, 5000);
          }
        }).catch((error) => {
          dispatch({
            type: 'IS_ERROR',
            payload: {
              error: error
            },
          });
          history.push("/something-went-wrong");
        });
    }
  };
  const handleClubName = (fieldName, value) => {
    setClubName(value);
    validateField(fieldName, value);
  }
  const handleNoOfTables = (fieldName, event) => {
    setNoOfTable(event.target.value);
    validateField(fieldName, event.target.value);
  }
  const handleAddress = (fieldName, value) => {
    setClubAddress(value);
    validateField(fieldName, value);
  }

let timeoutId;

const handleCity = (fieldName, value) => {
  setClubCity(value);
  if (value.length >= 3) {
    // Clear any existing timeout
    cleanup();
    // Set a new timeout
    timeoutId = setTimeout(() => {
      getCityAndCountry(value, user?.authtoken)
        .then((response) => {
          setSuggestions(response.data.cities.map((item) => item.label));
        })
        .catch((error) => {
          dispatch({
            type: 'IS_ERROR',
            payload: {
              error: error
            },
          });
          history.push("/something-went-wrong");
        });
    }, 500); // Adjust the delay (in milliseconds) as needed
  } else {
    setClubCity(value);
    setSuggestions([]);
  }
};

const search = (event) => {
  if (event.query.length >= 3) {
    cleanup();
    handleCity('city', event.query);
  } else {
    setClubCity(event.query);
    setSuggestions([]);
  }
};

const setUpClubCity = (value) => {
  delete errors['city'];
  setClubCity(value);
}

// Added a cleanup function 
const cleanup = () => {
  clearTimeout(timeoutId);
};

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ paddingBottom: "1%" }}>
          {
            props?.type === clubdata.setupclub.editclub ? 
            <div className="col-6 text-left">
              <Button label={clubdata.setupclub.back} icon="pi pi-angle-left" link style={{textDecoration:"none"}} onClick={() => history.go(-1)}/>
            </div>
            : 
            ""
          }
          
        </div>
        <div className="row">
          <div className="col-md-12">
            <Card title={props?.type === clubdata.setupclub.editclub ? clubdata.setupclub.editclubprofile : clubdata.setupclub.clubprofile}>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-md-12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Avatar style={{border: errors.logo ? "1px solid #dc3545" : "1px solid gray"}} image={file && file.previewURL ? file.previewURL : file} shape="circle" />
                                <FileUpload style={{ backgroundColor: "#DEB215", marginTop:"5%" }} className="browsebutton" mode="basic" name="demo[]" accept="image/*" onSelect={handleFileChange} auto chooseLabel={clubdata.setupclub.browse} />
                                {errors.logo && (
                                  <div style={{color: "#dc3545", display:"flex", fontSize:"80%"}}>{errors.logo}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8" style={{marginTop:"1%"}}>
                            <div className="row">
                              <div className="form-group" style={{ width: "100%" }}>
                                <label style={{textTransform:"capitalize", fontSize:"1rem"}}>{clubdata.setupclub.clubname}<span className="redStar">*</span></label>
                                <InputText
                                  className={`form-control ${errors.clubname ? "is-invalid" : ""}`}
                                  placeholder={clubdata.setupclub.clubname}
                                  type="text"
                                  name={clubdata.setupclub.clubname.toLowerCase()}
                                  onChange={(event) => handleClubName('clubname', event.target.value)}
                                  value={clubName}
                                />
                                {errors.clubname && (
                                  <div className="invalid-feedback">{errors.clubname}</div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12" style={{padding:"0px"}}>
                                <div className="form-group" style={{width:"100%"}}>
                                  <label style={{textTransform:"capitalize", fontSize:"1rem"}}>{clubdata.setupclub.nooftables}<span className="redStar">*</span></label>
                                  <InputText 
                                    className={`form-control ${errors.noOfTable ? "is-invalid" : ""}`}
                                    value={noOfTable}
                                    name={clubdata.setupclub.nooftables.toLowerCase()}
                                    onChange={(event) => handleNoOfTables('noOfTable',event)}
                                    style={{display:'flex'}}
                                    maxLength={3}
                                    keyfilter="int" 
                                    placeholder={clubdata.setupclub.nooftables} 
                                    disabled={props?.type === clubdata.setupclub.editclub ? true : false}
                                  />
                                  {errors.noOfTable && (
                                    <div className="invalid-feedback">{errors.noOfTable}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{clubdata.setupclub.clubaddress}<span className="redStar">*</span></label>
                      <InputText
                        className={`form-control ${errors.address ? "is-invalid" : ""}`}
                        placeholder={clubdata.setupclub.clubaddress}
                        name={clubdata.setupclub.clubaddress.toLowerCase()}
                        type="text"
                        onChange={(event) => handleAddress('address', event.target.value)}
                        value={clubAddress}
                      />
                      {errors.address && (
                        <div className="invalid-feedback">{errors.address}</div>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{clubdata.setupclub.city}, {clubdata.setupclub.country}<span className="redStar">*</span></label>
                      <div className="card flex justify-content-center" style={{marginBottom:"2px", border: errors.city ? '1px solid #dc3545':'1px'}}>
                        <AutoComplete style={{width:'100%'}}value={clubCity} suggestions={suggestions} completeMethod={search} onChange={(event) => setUpClubCity(event.value)} />
                      </div>
                      {errors.city && (
                        <div style={{width: '100%',fontSize: '80%',color: '#dc3545',top: '1px'}}>{errors.city}</div>
                      )}
                    </div>
                  </div>
                </div>
                <Divider/>
                <div className="row">
                  <div className="col-md-12">
                    <p style={{fontSize: '1.5rem', fontWeight:"700"}}>Club Settings</p>
                  </div>
                  <div className="col-md-12">
                    <p>Payment Options</p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group d-flex">
                      <div className="d-flex align-items-center">
                        <RadioButton
                          className="p-checkbox"
                          inputId="payPerSign"
                          name="paymentOption"
                          value="per_sign"
                          onChange={(e) => setSelectedPaymentOption(e.value)}
                          checked={selectedPaymentOption === 'per_sign'}
                        />
                        <label htmlFor="payPerSign" className="ml-2 mb-0 pr-4">
                          Pay Per Sign
                        </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <RadioButton
                          className="p-checkbox"
                          inputId="payPerNight"
                          name="paymentOption"
                          value="per_night"
                          onChange={(e) => setSelectedPaymentOption(e.value)}
                          checked={selectedPaymentOption === 'per_night'}
                        />
                        <label htmlFor="payPerSign" className="ml-2 mb-0 pr-4">
                          Pay Per Night
                        </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <RadioButton
                          className="p-checkbox"
                          inputId="both"
                          name="paymentOption"
                          value="both"
                          onChange={(e) => setSelectedPaymentOption(e.value)}
                          checked={selectedPaymentOption === 'both'}
                        />
                        <label htmlFor="both" className="ml-2 mb-0">
                          Both
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <Button
                  className="btn-fill pull-right saveButton"
                  type="submit"
                  variant="info"
                  onClick={(event) => props?.type === clubdata.setupclub.editclub ? handleEdit(event) : handleSave(event)}
                >
                  {clubdata.setupclub.save}
                </Button>
                <div className="clearfix"></div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClubSetup;
