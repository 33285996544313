import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import clubtextdata from '../../assets/json/patextdata.json';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clublist from '../../assets/json/patextdata.json';
const ClubRightSidebar = ({showProfile,clubData, setShowProfile}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePromocodeClick = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/promocodes?id=${rowData.ID}`);
  };
    return(
        <Sidebar visible={showProfile} position="right" onHide={() => setShowProfile(false)}>
          <div className="p-grid p-fluid">
            <div className="p-col-12 d-flex justify-content-center align-items-center">
              <Avatar image={clubData?.LogoUrl} size="xlarge" shape="circle" />
            </div>
            <div className="p-col-12 d-flex flex-column justify-content-center align-items-center">
              <h3 className="text-center">{clubData?.ClubName}</h3>
              <strong>{clubtextdata.clubTablesText.code} {clubData?.ClubCode}</strong>
            </div>
            <Divider />
            <div className="p-col-12">
              <i className="pi pi-building"></i>
              <span className="profilecontentMargin">{clubData?.ClubCity}</span>
            </div>
            <Divider />
            <div className="p-col-12">
              <i className="pi pi-envelope"></i>
              <span className="profilecontentMargin">{clubData?.ClubAddress ? clubData?.ClubAddress : "-"}</span>
            </div>
            <Divider/>
            <div className="p-col-12">
              <i className="pi pi-table"></i>
              <span className="profilecontentMargin">{clubtextdata.clubTablesText.clubtables} <Badge style={{float:"right"}} value={clubData?.ClubTablesCount ? clubData?.ClubTablesCount : 0}></Badge></span>
            </div>
            <Divider />
            <div className="p-col-12">
              <i className="pi pi-users"></i>
              <span className="profilecontentMargin">{clubtextdata.clubTablesText.clubaccounts} <Badge style={{float:"right"}} value={clubData?.ClubUsersCount ? clubData?.ClubUsersCount : 0}></Badge></span>
            </div>
            <Divider />
            <div className="p-col-12">
              <i className="pi pi-desktop"></i>
              <span className="profilecontentMargin">{clubtextdata.clubTablesText.screens} <Badge style={{float:"right"}} value={clubData?.ClubScreensCount ? clubData?.ClubScreensCount : 0}></Badge></span>
            </div>
            <Divider />
            <div className="p-col-12">
              <i className="pi pi-ticket"></i>
              <span 
                className="profilecontentMargin" 
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                onClick={()=>handlePromocodeClick(clubData)}
              >
                Promocodes
              </span>
            </div>
          </div>
      </Sidebar>
    )
}
export default React.memo(ClubRightSidebar);