import axios from "axios";
import { apiPrefix } from "config";

export const getPromoCodes = async(authtoken, clubId) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/promocodes/${clubId}`, {
        headers: {
            token: authtoken,
        } 
    });
}

export const addPromoCode = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/promocode`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const updatePromoCode = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/promocode`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const deletePromoCode = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/promocode/${id}`, {
        headers: {
            token: authtoken
        }
    });
}

